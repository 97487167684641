.pageNumbers {
  display: inline-block;
}

.pageNumbers li {
  list-style-type: none;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 200px;
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 5px;
  font-weight: bold;
}

.pageNumbers li.active {
  background-color: #4CAF50;
  color: white !important;
  border: 1px solid #4CAF50;
}

.pageNumbers li.disallow {
  pointer-events: none;
}

.pageNumbers a:hover:not(.active) {background-color: #ddd;}