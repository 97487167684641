@import url("https://fonts.googleapis.com/css?family=Arimo");


.wrapper {
  margin-top: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Arimo";
}

.second{
    border-left: 1px dotted #000;
}

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
table{
    width: 100%;
    padding: 8px 8px;
    text-align: center;
}
table img{
    border-radius: 10px;
    width: 50px !important;
    height: 50px !important;
}
table td img{
    width: 120px;
    height: auto;
}
table th{
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    color: #000;
}


.container12 {
  width: 30%;
  /* padding: 5% 10%; */
}

.mo_cartTitles1{
  margin-left: 4px;
}

.mo_cartTitles2{
  margin-left: 88px;
}
.mo_cartTitles3{
  margin-left: 70px;
}
.mo_cartTitles4{
  margin-left: 23px;
}
.mo_cartTitles5{
  margin-left: 17px;
}
.mo_cartTitles6{
  margin-left: 18px;
}
.mo_cartTitles7{
  margin-left: 15px;
}
.mo_cartTitles8{
  margin-left: 5px;
}

.container13 {
  width: 50%;
  /* padding: 5% 10%; */
  margin : 0px 0px 0px 16px;
  padding: 0px 10px 0px 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 610px;
}

h1 {
  align-self: center;
}

form {
  width: 100%;
}
form > * {
  /* margin-top: 20px; */
}
form input {
  width: 15%;
  min-height: 15px;
  border: 0;
  font-size: 14px !important;
  /* letter-spacing: 0.15rem; */
  font-family: "Source Sans Pro", sans-serif;
  margin-top: 5px;
  color: #000;
  border-radius: 4px;
}
form label {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  color: #000;
}
form h1 {
  font-size: 15px;
  line-height: 30px;
  color: green;
  letter-spacing: 1px;
}
form h1:nth-of-type(2) {
  margin-top: 1%;
}

.name {
  justify-content: space-between;
  display: flex;
  width: 100%;
}
.name div {
  width: 45%;
}

.address-info {
  display: flex;
  justify-content: space-between;
}
.address-info input{
  margin-top: 0px;
  margin-right: 4px;
}
.address-info div {
  /* width: 30%; */
}

.cc-info {
  display: flex;
  justify-content: space-between;
}
.cc-info div {
  width: 45%;
}

.btns {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.btns button {
  margin: 3px 0;
  height: 30px;
  width: 40%;
  color: #cfc9e1;
  background-color: #4a3b76;
  text-transform: uppercase;
  border: 0;
  border-radius: 0.3rem;
  letter-spacing: 2px;
}
.btns button:hover {
  animation-name: btn-hov;
  animation-duration: 550ms;
  animation-fill-mode: forwards;
}

@keyframes btn-hov {
  100% {
    background-color: #cfc9e1;
    color: #4a3b76;
    transform: scale(1.05);
  }
}
input:focus,
button:focus {
  outline: none;
}

@media (max-width: 736px) {
  .wrapper {
    width: 100%;
  }

  .container12 {
    width: 100%;
  }

  .container13 {
    width: 100%;
  }

  .btns {
    align-items: center;
  }
  .btns button {
    width: 50%;
  }

  form h1 {
    text-align: center;
  }

  .name,
.address-info,
.cc-info {
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }
  .name div,
.address-info div,
.cc-info div {
    align-items: center;
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .street,
.cc-num {
    text-align: center;
  }

  input {
    margin: 5px 0;
    min-height: 30px;
  }
}