*{
  box-sizing: border-box;
}

.css-2b097c-container{ 
  z-index : 1;
}

.loc_tax{
  z-index : unset !important;
}
.bdHMCv {
    z-index: 1 !important;
}

.paymentheading{
  font-size: 15px;
    line-height: 30px;
    color: green;
    letter-spacing: 1px;
}

.rs_product{
  z-index: unset !important;
}

.pickup_location_info{
  text-align: left !important;
}

.mo_card_information {
  display: inline-block !important;
  width: 100% !important;
}

.product_addon_category_heading{
  padding: 11px;
    margin: 0px 0px 6px 10px;
    font-size: 19px;
}
.approval_status_btn1{
  padding: 0px 8px;
    background-color: orange;
    margin-top: 6px;
    margin-left: 30px;
    height: 24px;
    color: #fff;
    border-radius: 4px;
    font-size: "12px";
}

.driver-modal-body{
  overflow-y: scroll;
  height: 350px;
}

.pickup_location_mo_select{
  margin-top : 15px;
  text-align: left;
  z-index : unset !important;
}

.approval_status_btn2{
  padding: 0px 8px;
    background-color: green;
    margin-top: 6px;
    margin-left: 30px;
    height: 24px;
    color: #fff;
    border-radius: 4px;
    font-size: "12px";
}
.approval_status_btn3{
  padding: 0px 8px;
    background-color: red;
    margin-top: 6px;
    margin-left: 30px;
    height: 24px;
    color: #fff;
    border-radius: 4px;
    font-size: "12px";
}
.manual_order_tbl{
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  color: #555;
  border-collapse: collapse;
  width: 100%;
}
.mo_td{
  border: 1px solid #dddddd;
  text-align: left;
  padding: 5px;
}
.top_search_img{
  width: 35px;
    height: 37px;
    margin-top: 0px
}
.link_search{
  
}
.custom_label_file_name{
  margin-top: 0px;
}
.dateClass{
 width: initial !important;
 position: relative;
}
.calendar_icon{
  right: 145px !important;
  position: absolute;
}
.transactions_calender_icon {
    right: -30px !important;
    position: absolute;
    top: 10px;
}
body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  font-size: 14px;
  background: #f8f9fd;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
  font-weight: 600;
}
p {
  color: #555;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.6;
}
p:last-child {
  margin-bottom: 0;
}
.ck-editor__editable {
  min-height: 200px;
}
a {
  color: #333;
}
a:hover {
  text-decoration: none;
  color: #319f60;
}
img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
button:focus {
  outline: none;
}
.btn_sty_1 {
  background: #fff;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  padding: 12px 20px;
  border: none;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.btn_sty_1:hover {
  background: #2e9259;
  color: #fff;
  text-decoration: none;
}
.btn_sty_1:focus {
  outline: none;
}
.btn_sty_1.sm_btn1 {
  padding: 5px 20px;
}
.btn_sty_2 {
  background: #555555;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  padding: 12px 20px;
  border: none;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.btn_sty_2:hover {
  background: #2e9259;
  color: #fff;
  text-decoration: none;
}
.btn_sty_2:focus {
  outline: none;
}
.star_rating_checked {
  color: orange;
}
.star_rating_unchecked{
  color : lightgrey;
}
.grey_btn {
  background: #e6e6e6;
  color: #555;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  padding: 12px 20px;
  border: none;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.grey_btn:hover {
  background: #2e9259;
  color: #fff;
  text-decoration: none;
}
.grey_btn:focus {
  outline: none;
}
/* end:reset */
.account_form {
  width: 380px;
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.account_form .btn_sty_1 {
  width: 100%;
}
.account_form label {
  font-size: 14px;
  font-weight: 700;
}
.account_icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 2px 5px rgba(000, 000, 000, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.account_icon_wrap {
  display: inline-block;
}
.account_page_title {
  font-size: 25px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.account_bottom_link {
  font-size: 15px;
}
p.forgot_pass_info {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
}
/* home start */
.header_inside {
  position: sticky;
  top: 0;
  z-index: 1000;
}
.header_inside .top_nav.bg-dark {
  background: #319f60 !important;
  padding-left: 0;
  padding-right: 0;
  min-height: 70px;
}
.header_wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.head_selector_wrap {
  max-width: 100%;
  position: relative;
  margin-top : 17px;
}
.head_selector_wrap::after {
  content: "\eab2";
    position: absolute;
    right: 0;
    top: 5px;
    color: #fff;
    font-family: IcoFont;
    pointer-events: none;
    font-size: 19px;
}
.canvasjs-chart-credit{
  display : none;
}
.head_selector_wrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  color: #fff;
  padding: 0 20px 0 10px;
  font-size: 13px;
}
.head_selector_wrap select option {
  color: #333;
}
.head_selector_wrap select:focus {
  outline: none;
}
.notification_wrap {
  width: 40px;
  text-align: center;
}
.notification {
  position: relative;
  display: block;
}
.notification_indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e74c3c;
  position: absolute;
  top: 0;
  right: 4px;
}
.middle_block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.left_sidebar {
  width: 250px;
  overflow-y: auto;
  height: calc(100vh - 70px);
  white-space:nowrap;
overflow-y: scroll;
-webkit-overflow-scrolling: touch;
}
@supports (-webkit-touch-callout: none) {
.left_sidebar{
white-space:nowrap;
overflow-y: scroll;
-webkit-overflow-scrolling: touch;  
}
}
.left_sidebar_bg {
  background: #fff;
  width: 250px;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  box-shadow: 1px 0 1px rgba(000, 000, 000, 0.1);
}

.left_sidebar ul {
  padding: 5px 0;
  margin: 0;
  position: fixed;
  z-index: 2;
  width: 250px;
  height: calc(100vh - 70px);
  overflow-y: auto;
}
.left_sidebar ul li {
  list-style: none;
}
.left_sidebar ul li a {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  color: #555;
}
.left_sidebar ul li a span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
}

.force-overflow {
  min-height: 450px;
}

.productdropodownManualOrder {
  z-index: unset;
}

.mdl_order_body{
  height: auto;
}


/* @media screen and (min-width: 1440px) {
  .large-2{
    overflow-y: scroll;
    height: 800px;
  }
}

@media screen and (max-width: 1440px) {
  .large-2{
    overflow-y: scroll;
    height: 700px;
  }
}

@media screen and (min-width: 2500px) {
  .large-2{
    overflow-y: scroll;
    height: 1000px;
  }
} */


/* .large-2::-webkit-scrollbar-track {
  border: 1px solid #000;
  padding: 2px 0;
  background-color: #fff;
}

.large-2::-webkit-scrollbar {
  width: 10px;
}

.large-2::-webkit-scrollbar-thumb {
  border-radius: 2px;
  box-shadow: inset 0 0 1px #fff;
  background-color: rgb(211, 216, 211);
  border: 1px solid #000;
} */

/* width */
.mo_pd::-webkit-scrollbar {
  width: 10px;
  left: 100;
  display: inline-block;
}

.manual_order_titles,.mo_label_title{
  float: left;
}

.jMcrRR > input{
  font-size: 14px !important;
  font-family: "Source Sans Pro", sans-serif !important;
  letter-spacing: 0em;
}

.custText > input{
  font-size: 13px !important;
  letter-spacing: 1px !important;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* Track */
.mo_pd::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.mo_pd::-webkit-scrollbar-thumb {
  background: rgb(174, 168, 168);
}

/* Handle on hover */
.mo_pd::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.autocomplete-dropdown-container{
  z-index: 9999;
  position: absolute;
}
.left_sidebar ul li a:hover {
  text-decoration: none;
  color: #319f60;
}
.left_sidebar ul li a:hover span svg {
  fill: #319f60;
}
.left_sidebar ul li.nav-active a {
  color: #319f60;
}
.left_sidebar ul li.nav-active a svg {
  fill: #319f60;
}
.main_content {
  width: calc(100% - 250px);
}
.welcome_message {
  margin: 15px 0 0 0;
}
.head_sty1 {
  font-size: 25px;
}
.dash_stats {
  margin-top: 20px;
}
.dash_stats .row {
  margin-left: -8px;
  margin-right: -8px;
}
.dash_stats .col-md-4 {
  padding-left: 8px;
  padding-right: 8px;
}
.stat_box {
  width: 100%;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 0 5px rgba(000, 000, 000, 0.1);
  display: flex;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 15px;
}
.panel-body-cus {
  padding-bottom: 10px;
}
.panel-default-sty {
  border-bottom: 1px solid #ccc;
  padding: 5px 0px 10px;
}
.stat_box .icon_box {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: #e1f2fa;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.stat_box .stat_info h2 {
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 0 0;
}
.stat_box .stat_info h3 {
  font-size: 16px;
  font-weight: 400;
  color: #666;
  margin: 0;
}
.sec_title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.sec_title1 {
  margin: 20px 0px 0px 15px;
}
.sec_title .head_sty1 {
  margin: 0;
}
.check_container {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.check_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #707070;
}
.check_container:hover input ~ .checkmark {
  background-color: #ccc;
}
.check_container input:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid #707070;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.check_container input:checked ~ .checkmark:after {
  display: block;
}
.check_container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #555;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.recent_orders {
  margin-top: 10px;
}
.r_order_box {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(000, 000, 000, 0.1);
}
.r_order_top {
  padding: 15px 15px 15px 15px;
  display: flex;
  flex-wrap: wrap;
}
.r_order_top .img_box {
  width: 100px;
  height: 80px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 3px;
}
.r_order_top .img_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.r_align_right {
  text-align: right;
}
.r_order_info {
  width: calc(100% - 115px);
}
.r_order_info .r_name {
  font-size: 22px;
}
.r_order_info h3 {
  font-size: 14px;
}
.r_order_info h3 a {
  color: #319f60;
}
.r_order_info h4 {
  font-size: 14px;
  font-weight: 400;
}
.r_order_info h4 span {
  font-weight: 600;
}
.r_order_info h3.price {
  font-size: 18px;
  color: #319f60;
}
.r_order_bottom {
  padding: 15px 15px 15px 15px;
  border-top: 1px dashed #d1d1d1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.r_eta {
  font-size: 15px;
  font-weight: 400;
}
.r_eta span {
  font-weight: 600;
}
.r_dateandtime {
  font-size: 15px;
  font-weight: 400;
}
.r_status {
  position: relative;
  cursor: pointer;
}
.status_options {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  width: 170px;
  padding: 5px 10px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 50;
  display: none;
}
.status_options .status_item {
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0;
}
.status_options.open {
  display: block;
}
.status_selected {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.status_selected svg {
  margin-left: 10px;
}
.home_driver_sec {
  margin-top: 20px;
}
.driver_map_wrap {
  width: 100%;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 0 5px rgba(000, 000, 000, 0.1);
  padding: 15px;
  margin-bottom: 20px;
}
.driver_map_wrap iframe {
  width: 100%;
  height: 340px;
}
.sales_graph {
  margin-top: 20px;
}
.s_graph_wrap {
  width: 100%;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 0 5px rgba(000, 000, 000, 0.1);
  padding: 15px;
}
.s_date_wrap {
  position: relative;
}
.s_date_wrap .calendar_icon {
  position: absolute;
  right: 10px;
  top: 14px;
  pointer-events: none;
  background-color: #fff;
}

.s_date_wrap .form-control {
  padding-right: 35px;
}
.menu_icon {
  color: #fff;
  font-size: 18px;
  visibility: hidden;
  pointer-events: none;
}
.manage_staff {
  margin-top: 10px;
  margin-bottom: 10px;
}
.m_staff_box {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(000, 000, 000, 0.1);
}
.m_staff_top {
  padding: 15px 15px 15px 15px;
  display: flex;
  flex-wrap: wrap;
}
.m_staff_top .img_box {
  width: 165px;
  height: 128px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 3px;
}
.m_staff_top .img_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.m_staff_right {
  text-align: right;
}
.m_staff_info {
  width: calc(100% - 180px);
}
.m_staff_info .r_name {
  font-size: 28px;
}
.m_staff_info h3 {
  font-size: 14px;
}
.m_staff_info h3 a {
  color: #319f60;
}
.m_staff_info h4 {
  font-size: 16px;
  font-weight: 400;
}
.m_staff_info h4 span {
  font-weight: 600;
}
.m_staff_info h3.price {
  font-size: 18px;
  color: #319f60;
}
.m_staff_bottom {
  padding: 10px 0 0 0;
  border-top: 1px dashed #d1d1d1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.r_eta {
  font-size: 15px;
  font-weight: 400;
}
.r_eta span {
  font-weight: 600;
}
.r_dateandtime {
  font-size: 15px;
  font-weight: 400;
}
.m_staff_bottom .btn_group .grey_btn {
  margin: 0 3px;
}
/*banners css*/
.manage_banners {
  margin-top: 10px;
  margin-bottom: 10px;
}
.m_banners_box {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(000, 000, 000, 0.1);
}
.m_banners_top {
  padding: 15px 15px 15px 15px;
  display: flex;
  flex-wrap: wrap;
}
.m_banners_top .img_box {
  width: 250px;
  height: 128px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 3px;
}
.m_banners_top .img_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.m_banners_right {
  text-align: right;
}
.m_banners_info {
  width: calc(100% - 265px);
}
.m_banners_top .img_box {
  width: 250px;
}
.m_banners_info .r_name {
  font-size: 28px;
}
.m_banners_info h3 {
  font-size: 14px;
}
.m_banners_info h3 a {
  color: #319f60;
}
.m_banners_info h4 {
  font-size: 16px;
  font-weight: 400;
}
.m_banners_info h4 span {
  font-weight: 600;
}
.m_banners_info h3.price {
  font-size: 18px;
  color: #319f60;
}
.m_banners_bottom {
  padding: 10px 0 0 0;
  border-top: 1px dashed #d1d1d1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.m_banners_bottom .btn_group .grey_btn {
  margin: 0 3px;
}
.m_banners_promo {
  width: calc(100% - 0px);
}
.m_banners_promo .r_name {
  font-size: 28px;
}
.m_banners_promo h3 {
  font-size: 14px;
}
.m_banners_promo h3 a {
  color: #319f60;
}
.m_banners_promo h4 {
  font-size: 16px;
  font-weight: 400;
}
.m_banners_promo h4 span {
  font-weight: 600;
}
.m_banners_promo h3.price {
  font-size: 18px;
  color: #319f60;
}
/*end banners css*/
.view_id_btn_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.view_id_btn {
  font-weight: 600;
  display: inline-block;
}
ul.custom_pagination {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
ul.custom_pagination li {
  list-style: none;
  margin: 3px;
}
ul.custom_pagination li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b4b4b4;
  color: #333;
  font-size: 15px;
}
ul.custom_pagination li a:hover {
  text-decoration: none;
  background: #319f60;
  border-color: #319f60;
  color: #fff;
}
ul.custom_pagination li.active a {
  background: #319f60;
  border-color: #319f60;
  color: #fff;
}
.recent_transactions_page ul.custom_pagination {
  margin-top: 15px;
}
.modal-body {
  background: #f8f9fd;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.modal-header {
  border-bottom: none;
  box-shadow: 0 5px 5px rgba(000, 000, 000, 0.1);
  z-index: 1;
  align-items: center;
}
.modal-title {
  font-size: 20px;
  font-weight: 600;
}
.close {
  font-size: 18px;
}
.form-group label {
  font-weight: 700;
}
.staff_profile_sec {
  display: flex;
  flex-wrap: wrap;
}
.staff_profile_pic_box {
  width: 200px;
  margin-right: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.mng-profile {
  margin: 0 auto;
  margin-top: 15px;
}
.staff_profile_img {
  width: 200px;
  height: 200px;
  overflow: hidden;
}
.staff_profile_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/*
.staff_profile_form {
  width: calc(100% - 230px);
}
*/
.edit_promo_code_form {
  width: calc(100% - 0px);
}
.upload_pic_button {
  background: #8b8b8b;
  color: #fff;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 20px;
  border: 1px solid #ced4da;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.upload_pic_button:hover {
  color: #fff;
  background: #999;
}
.staff_profile_btn_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.location_box .m_staff_top .img_box {
  width: 200px;
  height: 155px;
}
.location_box .m_staff_info {
  width: calc(100% - 215px);
}
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  margin: 0;
}
.removeBtnBox{
  border-radius: 100px;
    background: rgb(229, 219, 219);
    padding: 0px;
    color: black;
    width: 23px;
    text-align: center;
    text-decoration: none;
    padding: 3px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 0;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #6c7b86;
}
input:checked + .slider {
  background-color: #319f60;
}
input:focus + .slider {
  box-shadow: 0 0 1px #319f60;
}
input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
.slider.round {
  border-radius: 12px;
}
.slider.round:before {
  border-radius: 50%;
}
.category_actions ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.category_actions ul li {
  list-style: none;
  margin-left: 10px;
}
.category_actions ul li a {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #707070;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category_bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.category_box .m_staff_top .img_box {
  width: 90px;
  height: 65px;
}
.category_box .m_staff_info {
  width: calc(100% - 115px);
}
.category_box .m_staff_info .r_name {
  font-size: 18px;
}
.category_form {
  padding-bottom: 15px;
  border-bottom: 1px dotted #d1d1d1;
  margin-bottom: 15px;
}
.category_form .staff_profile_pic_box {
  width: 100px;
  margin-right: 20px;
}
.category_form .staff_profile_img {
  width: 100px;
  height: 70px;
  border-radius: 3px;
}
.category_form .staff_profile_form {
  width: calc(100% - 120px);
}
.category_form .upload_pic_button {
  padding: 8px 10px;
}
.category_form.add_cate_form {
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
p.custom_label_file_name {
  overflow: hidden !important;
  white-space: nowrap !important;
}
.content_block {
  margin-top: 10px;
  margin-bottom: 10px;
}
.search_box_wrap {
  position: relative;
}
.search_box_wrap .search_icon {
  position: absolute;
  right: 105px;
  top: 9px;
}
.search_box_wrap.search_customer {
  width: 300px;
  max-width: 100%;
}
.curbside_info {
  margin: 10px 0;
}
.curbside_info h3 {
  font-size: 16px;
  font-weight: 600;
}
.curbside_info h5 {
  font-size: 14px;
  font-weight: 400;
}
/*
 * bootstrap-tagsinput v0.8.0
 * 
 */
.range-bor {
  border: 1px solid #ced4da;
}
.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 4px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  line-height: 28px;
  cursor: text;
}
.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
}
.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1;
}
.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #777;
}
.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777;
}
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}
.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: #495057;
  background-color: #e2e2e2;
  border-radius: 3px;
  padding: 5px 10px;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
/* end tag input */
/*COLLAPSIBLE css*/
.panel-heading {
  padding: 0;
  border: 0;
}
.panel-title > a,
.panel-title > a:active {
  display: block;
  padding: 15px 0px 0px 0px;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}
.panel-heading a:before {
  font-family: "Glyphicons Halflings";
  content: "\e114";
  float: right;
  transition: all 0.5s;
}
.panel-heading.active a:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.taxations_box .m_staff_info {
  width: 100%;
}

.settings_page {
  margin-top: 10px;
  margin-bottom: 10px;
}
.toggle_block {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  padding: 15px 20px;
  border-radius: 3px;
  height: 100px;
}
.toggle_block h3 {
  font-size: 18px;
  font-weight: 600;
}
.heading_settings {
  font-size: 24px;
  font-weight: 600;
}
.m_staff_cust{
  padding: 20px;
}
.change_password {
  margin-bottom: 35px;
}
.setting_vault {
  margin-bottom: 25px;
}
.payment_integrations {
  margin-bottom: 25px;
}
.my_profile {
  margin: 10px 0;
}

.product_review_customer{
      vertical-align: middle;
    border-style: none;
    width: 70px;
    height: 70px;
    border-radius: 100px;
    margin-right: 20px;
}

.sec_title .btn_sty_1 {
  padding: 8px 20px;  
  margin-left: 25px;

}
.transactions_report_title .btn_sty_1 {
  margin-left: 10px;
}
.transaction_table {
  background: #fff;
  border: 1px solid #dee2e6;
}

.product_page {
  margin-top: 10px;
  margin-bottom: 10px;
}
.product_top_block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}
.btn_graph{
  background: white;
  border: 1px solid #dee3d9;
  cursor: pointer;
}
.product_search {
  flex-grow: 1;
  position: relative;
}
.datePickerPromoCode .react-datepicker-popper {
    right: 120px !important;
}
@media screen and (-webkit-min-device-pixel-ratio:0) { 
  #safari { display: block; } 
} 
/* Safari 10.0 (not 10.1) */

_::-webkit-:host:not(:root:root), .safari_only {

  background-color:#fff; 

}
@media screen and (min-width: 600px) and (max-width: 1024px) {
/*
  .manual_order_mc{
    width: 587px !important;
    left: 60px !important;
    margin: -10px 6px -3px -95px !important;
    max-width: 587px !important;
}
*/
}
.custom_search {
  position: relative;
}
.product_search a {
  position: absolute;
  right: 15px;
  top: 12px;
}
.product_search .form-control {
  padding: 10px 40px 10px 15px;
}
.product_top_block .btn_sty_1 {
  margin-left: 10px;
}
.product_top_block .btn_sty_2 {
  margin-left: 10px;
}

.order_page {
  margin-top: 10px;
  margin-bottom: 10px;
}
.order_top_block {
  margin-bottom: 20px;
}
.product_box {
  margin-bottom: 30px;
  position: relative;
}
.product_box .product_img img {
  border-radius: 3px 3px 0 0;
  cursor: pointer;
}
.product_box .product_info {
  background: #fff;
  box-shadow: 0 0 5px rgba(000, 000, 000, 0.1);
  padding: 21px 7px;
  border-radius: 0 0 3px 3px;
}
.product_box .product_info h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}
.product_box .product_info_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.num_review {
  font-size: 15px;
  font-weight: 600;
}
.product_box .product_img img {
  width: 100%;
}
/*
@media (min-width: 576px){
.modal-dialog {
    max-width: 1200px !important;
}}
*/
.custom_icon {
  margin-top: 0px !important;
  margin-left: -2px;
  font-size: 23px !important;
  color: #fff;
  background: red;
}
.product_box .product_details {
  width: 100%;
  height: 100%;
  /* display: block; */
  /* position: absolute; */
  top: 0;
  left: 0;
}

.product_detail_top {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.pro_del_img {
  width: 280px;
  margin-right: 20px;
}
.pro_del_img img {
  border-radius: 3px;
  width: 100%;
}
.pro_del_info {
  width: calc(100% - 300px);
}

.pro_stats_box {
  margin-bottom: 30px;
}
.pro_stats_box h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2px;
}
.pro_del_stats .btn_sty_1.edit {
  margin-right: 10px;
}

.pro_det_top_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pro_det_top_info h3 {
  margin-bottom: 0;
}
.pro_det_bottom_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.order_search {
  position: relative;
}
.order_search a {
  position: absolute;
  top: 8px;
  right: 12px;
  padding: 0px;
  background-color: #fff;
}

.pro_del_stats {
  margin-bottom: 30px;
}
.review_box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.review_box .user_pic {
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 32px;
  margin-right: 20px;
}
.review_box .user_pic img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.review_info {
  width: calc(100% - 84px);
}
.review_info h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}
.review_ext_info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.review_ext_info .rating {
  margin-right: 10px;
}
.cust_title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;
}

.pro_add_on {
  margin-bottom: 30px;
}
.pro_addon_title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.pro_addon_title h3 {
  font-size: 25px;
  margin-bottom: 0px;
  font-weight: 600;
}
.pro_addon_box {
  position: relative;
  box-shadow: 0 0 5px rgba(000, 000, 000, 0.1);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
}
.addon_pic {
  width: 90px;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 10px;
}
.addon_info {
  width: calc(100% - 100px);
}
.addon_info h3 {
  font-size: 18px;
  margin-bottom: 2px;
}
.addon_botom {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
}
.remove_addon {
  position: absolute;
  right: 10px;
  top: 10px;
}
.pro_detail_title h3 {
  font-size: 25px;
  font-weight: 600;
}
.addon_addbtn .btn_sty_1 {
  width: 100%;
  padding: 8px 20px;
}

.addon_pro_sec {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px dotted #c7c7c7;
  width: calc(100% - 20px);
  margin-left: 20px;
}
.oder_popup_headings {
  font-size: 22px;
}
.order_details_modal .r_order_top {
  align-items: center;
}
ul.order_addontag {
  margin: 0;
  padding: 0;
}
ul.order_addontag li {
  list-style: none;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  background: #e6e6e6;
  margin-right: 5px;
}
.r_summary_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}
.ord_box_wrap {
  padding: 15px 15px 15px 15px;
}
.r_summary_info.payment_method {
  justify-content: flex-end;
}

.driver_top_info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}
.driver_top_info .driver_pic {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 100%;
}
.driver_top_info .driver_pic img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.driver_top_info .driver_content {
  width: calc(100% - 70px);
  padding-left: 15px;
}
.driver_top_info .driver_content h3 {
  font-size: 20px;
  margin-bottom: 5px;
}
.ord_driver_info .driver_map iframe {
  width: 100%;
  height: 350px;
}

.edit_order_sec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}
.edit_order_pic {
  width: 100px;
  height: 80px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 3px;
}
.edit_order_pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.edit_order_qty {
  border: 1px solid #9e9e9e;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-left: auto;
}
.edit_order_qty .edit_text {
  width: 45px;
  height: 40px;
  border: none;
  background: #cce2d6;
  text-align: center;
}
.edit_order_qty .edit_text:focus {
  outline: none;
}
.edit_minus,
.edit_plus {
  padding-left: 10px;
  padding-right: 10px;
}
.edit_price {
  color: #319f60;
  font-size: 17px;
}
.edit_odr_info h2.r_name {
  font-size: 24px;
  margin-bottom: 5px;
}

.driver_list ul {
  padding: 0;
}
.driver_list ul li {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}
.assign_driver_pic {
  width: 100px;
  height: 80px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 3px;
}
.assign_driver_pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.assign_driver_info {
  width: calc(100% - 115px);
}
.assign_driver_info h3 {
  font-size: 22px;
}
.assign_driver_info h4 {
  font-size: 16px;
  font-weight: 400;
}

.assign_driver_check {
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  color: #707070;
  border: 1px solid #707070;
  position: absolute;
  top: 10px;
  right: 0;
  border-radius: 50%;
  background: #fff;
  font-size: 16px;
  display: none;
}

.driver_avl.selected .assign_driver_check {
  display: flex;
}
.s_date_wrap i.icofont-ui-calendar.transactions_calender_icon {
  position: absolute !important;
  /* right: 15px !important; */
}

/* responsive start */
@media only screen and (max-width: 1000px) {
  .menu_icon {
    visibility: visible;
    pointer-events: initial;
	  position: absolute;
	  right: 80px;
  }
  .left_sidebar {
    display: none;
  }
  .main_content {
    width: 100%;
    
  }
  .left_sidebar.menu_open {
    display: block;
    position: absolute;
  }
}
@media only screen and (max-width: 991px) {
  .staff_profile_pic_box {
    /* width: 100%; */
    margin-right: 0;
    margin-bottom: 15px;
    margin-right: 10px;
  }
  .staff_profile_form {
    /* width: 100%; */
  }

  .addon_pro_sec {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .r_align_right {
    text-align: left;
  }
  .r_dateandtime {
    width: 100%;
  }
  .view_id_btn_wrap {
    justify-content: flex-start;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .left_sidebar.menu_open {
    width: 100%;
  }
  .left_sidebar ul {
    width: 100%;
  }
  .left_sidebar_bg {
    width: 100%;
  }
  .m_staff_top .img_box {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .m_staff_info {
    width: 100%;
  }
  .r_order_top .img_box {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .r_order_info {
    width: 100%;
  }
  .s_date_wrap {
    width: 100%;
    margin-top: 10px;
  }
  .order_sort {
    width: 100%;
    margin-top: 10px;
  }
  .location_box .m_staff_top .img_box {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .location_box .m_staff_info {
    width: 100%;
  }
  .category_box .m_staff_top .img_box {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .category_box .m_staff_info {
    width: 100%;
  }
  .search_box_wrap.search_customer {
    width: 100%;
    margin-top: 10px;
  }

  .transactions_report_title .btn_sty_1 {
    margin-left: 0;
    margin-top: 10px;
  }
  .product_top_block .product_search {
    width: 100%;
    margin-bottom: 10px;
  }
  .product_top_block .btn_sty_1.add_new {
    margin-left: 0;
  }
}
/* responsive end */

/* responsive end */

/* * responsive start */
/* */
input[type="file"] {
  display: none;
}
.upload_pic_button {
  background: #8b8b8b;
  color: #fff;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px px;
  border: 1px solid #ced4da;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.active {
  /* color: green; */
  /* background-color: green !important; */
}
.pac-container {
  background-color: #fff;
  z-index: 2001;
  position: fixed;
  display: inline-block;
  float: left;
}
.sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.threedots:after {
  content: "\2807";
  font-size: 20px;
  color: darkgray;
}

.emailprofile{
  margin-bottom: -1rem !important;
}


  .custom-file-upload {
    background: #8b8b8b;
    border: 1px solid #ced4da;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: #fff;
    font-size: 15px;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }



@media screen and (min-width: 768px) {
  .custFileUpload {
    background: #8b8b8b;
    border: 1px solid #ced4da;
    display: inline-block;
    padding: 6px 6px;
    cursor: pointer;
    color: #fff;
    font-size: 11px;
    border-radius: 4px;
    padding: 8px 12px;
    width: 200px;
    text-align: center;
    margin-top: 215px;
    margin-left : -200px;
  }
}

@media screen and (max-width: 768px) {
  .profilefileupload {
    background: #8b8b8b;
    border: 1px solid #ced4da;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: #fff;
    font-size: 15px;
    border-radius: 4px;
    padding: 8px px;
    width: 96%;
    text-align: center;
    margin: 209px 0px 10px -197px;
}
}


@media screen and (min-width: 768px) {
  .profilefileupload {
    background: #8b8b8b;
    border: 1px solid #ced4da;
    display: inline-block;
    padding: 6px 6px;
    cursor: pointer;
    color: #fff;
    font-size: 11px;
    border-radius: 4px;
    padding: 8px 12px;
    width: 200px;
    text-align: center;
    margin-top: 215px;
    margin-left : -200px;
  }
}


.custom-file-upload-category {
  background: #8b8b8b;
  border: 1px solid #ced4da;
  display: inline-block;
  padding: 6px 6px;
  cursor: pointer;
  color: #fff;
  font-size: 11px;
  border-radius: 4px;
  padding: 8px 12px;
  width: 200px;
  text-align: center;
}


.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}
.upload_pic_button:hover {
  color: #fff;
  background: #999;
}
.myContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1 0px;
}

.myDatePicker {
  width: 100%;
}
.datepicker {
  z-index: 10000000000 !important;
}
.order-date-picker-css {
  border: none;
  width: 100%;
}

.myContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1 0px;
}

.myDatePicker {
  width: 200px;
  margin-right: 25px;
}
.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.pro_locations{
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.pro_locations > td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.pro_locations > tr:nth-child(even) {
  background-color: #dddddd;
}

.search_box_in{
  padding: 5px !important;
}

.redbtn {
  background: #e6e6e6;
  color: #555;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  padding: 12px 20px;
  border: none;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.redbtn:hover {
  background: #fa0909;
  color: #fff;
  text-decoration: none;
}
.custom_icon{
  margin-top: 0px !important;
    margin-left: -2px;
    font-size: 23px !important;
    color: #fff;
    background: red;
}
.redbtn:focus {
  outline: none;
}
.home-datepicker {
  padding-right: 30px;
}

.hrefcol{
  color: darkslategrey;
}

.loading{
  margin-left: 42%;
  margin-right: 50%;
  margin-top: 20%;
  width: 80px;
  height: 80px;
}

#locs{
  color: rgb(37, 39, 37);
    font-weight: 600;
    font-size: 16px;
}
/* Abhishek CSS */
.mng-calendar.form-control {
  max-width: 280px;
  float: right;
  margin-bottom: 15px;
}
.justify-content-center{
	justify-content: center !important;
}
.alert-info-section .alert-info{
	width: 100%;
}
.custom-order-popup .modal-dialog {
    max-width: 85%!important;
}
.address-info p {
  padding-left: 5px !important;
  padding-top: 4px !important;
}
.top-z{
  position: relative !important;
  z-index: 1 !important;
}
/*
@media (min-width: 768px) and (max-width: 992px) {
.globle-popup-width .modal-dialog {
 max-width: 85% !important;		
}
}*/
